
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'
import { stringUtils } from '@/utils/string'
import { User } from '@/apis/User'
import { UserRoleId } from '@/typings/AdminTyping'

@Component({
  name: 'AdminUsers',
  components: {
  }
})

export default class AdminUsers extends Mixins(TableMixin) {
  simpleTable = false
  usersList = {
    headers: [
      {
        text: 'Группа',
        align: 'start',
        value: 'group'
      },
      {
        text: 'Фамилия',
        align: 'start',
        value: 'last_name'
      },
      {
        text: 'Имя',
        align: 'start',
        value: 'first_name'
      },
      {
        text: 'Email',
        align: 'start',
        value: 'email'
      },
      {
        text: 'Телефон',
        align: 'start',
        value: 'phone'
      },
      {
        text: 'Статус',
        align: 'start',
        value: 'status'
      },
      {
        text: 'Редактировать',
        align: 'start',
        value: 'edit'
      },
      {
        text: 'Чат',
        align: 'start',
        value: 'chat'
      }
    ],
    items: []
  }

  isLoading = true
  tgUsersCount = ''

  created() {
    this.getUsers(0)
  }

  chatLink(item) {
    return item.support_chat_ds ? `/admin/chats/${item.support_chat_ds.id}` : `/admin/chats/${item.support_chat_lc.id}`
  }

  getUsers(categoryId) {
    User.fetchUsers(categoryId)
      .then(({ data }) => {
        this.usersList.items = data.data
        this.isLoading = false
      })
    User.getTgUsersCount().then(({ data }) => {
      this.tgUsersCount = data
    })
  }

  getActiveUsers(categoryId: number) {
    User.fetchActiveUsers(categoryId)
      .then(({ data }) => {
        this.usersList.items = data.data
        this.isLoading = false
      })
  }

  get dealershipManagerId() {
    return UserRoleId.ManagerDealership
  }

  get leasingCompanyManagerId() {
    return UserRoleId.ManagerLeasingCompany
  }

  get leasingCompanySuperId() {
    return UserRoleId.SupervisorLeasingCompany
  }

  get adminId() {
    return UserRoleId.Admin
  }

  get apiId() {
    return UserRoleId.ApiUser
  }

  get search() {
    return this.$store.state.common.searchAdminUsers
  }

  set search(item) {
    this.$store.state.common.searchAdminUsers = item
  }

  countUsers(number: number): string {
    return stringUtils.pluralForm(number, ['пользователь', 'пользователя', 'пользователей'])
  }
}
